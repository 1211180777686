import React from 'react';
import Chat, {   Bubble,
  MessageProps,
  useMessages,
  QuickReplyItemProps,
  useQuickReplies,
  Card,
  CardMedia,
  CardTitle,
  CardText,
  CardActions,
  Button,
  List,
  ListItem,
  Flex,
  FlexItem,
  ScrollView,
  RecorderHandle,
  ToolbarItemProps,
  RateActions, } from '@chatui/core';
// 引入样式
import { Icon } from '@chatui/core';

import '@chatui/core/dist/index.css';

const initialMessages = [
  {
    type: 'text',
    content: { text: '亲，我是你的私人助理小酷，有什么可以帮你的吗？' },
    user: { avatar: '//gw.alicdn.com/tfs/TB1DYHLwMHqK1RjSZFEXXcGMXXa-56-62.svg' },
  },
  {
    type: 'image',
    content: {
      picUrl: 'https://zhengxin-pub.cdn.bcebos.com/financepic/0460c4b11320281de9a4a9dd6bb416d4_fullsize.jpg',
    },
  },
];


const toolbar: ToolbarItemProps[] = [
  {
    type: 'smile',
    icon: 'smile',
    title: '表情',
  },
  {
    type: 'orderSelector',
    icon: 'shopping-bag',
    title: '宝贝',
  },
  {
    type: 'image',
    icon: 'image',
    title: '图片',
  },
  {
    type: 'camera',
    icon: 'camera',
    title: '拍照',
  },
  {
    type: 'photo',
    title: 'Photo',
    img: '//gw.alicdn.com/tfs/TB1eDjNj.T1gK0jSZFrXXcNCXXa-80-80.png',
  },
];

// 默认快捷短语，可选
const defaultQuickReplies = [
  {
    // icon: 'message',
    name: '查询会员',
    // isNew: true,
    // isHighlight: true,
  },
  {
    name: '订单业绩',
    // isHighlight: true,
    // isNew: true,
  },
  {
    name: '会员数量',
    // isHighlight: true,
  },
  {
    name: '查看任务',
    // isHighlight: true,

  }, {
    name: '回访会员',
    // isHighlight: true,

  },
];

export default function() {
  // 消息列表
  const { messages, appendMsg, setTyping } = useMessages(initialMessages);
  const msgRef = React.useRef(null);
  window.msgRef = msgRef;
  // 发送回调
  function handleSend(type, val) {
    if (type === 'text' && val.trim()) {
      // TODO: 发送请求
      appendMsg({
        type: 'text',
        content: { text: val },
        position: 'right',
      });

      setTyping(true);

      // 模拟回复消息
      setTimeout(() => {
        appendMsg({
          type: 'text',
          content: { text: '亲，您遇到什么问题啦？请简要描述您的问题~' },
        });
      }, 1000);
    }
  }

  // 快捷短语回调，可根据 item 数据做出不同的操作，这里以发送文本消息为例
  function handleQuickReplyClick(item) {
    handleSend('text', item.name);
  }

  function renderMessageContent(msg) {
    const { type, content } = msg;

    // 根据消息类型来渲染
    switch (type) {
      case 'text':
        return <Bubble content={content.text} />;
      case 'image':
        return (
          <Bubble type="image">
            <img src={content.picUrl} alt="" />
          </Bubble>
        );
      default:
        return null;
    }
  }

  function handleToolbarClick(item: ToolbarItemProps) {
    if (item.type === 'orderSelector') {
      appendMsg({
        type: 'order-selector',
        content: {},
      });
    }
  }

  function onStart(e) {
  console.log(e,'onStarte')
  console.log('onStart')
  }
  function onCancel(e) {
    console.log(e,'e')
    console.log('onCancel')
    }


  return (
    <Chat
    elderMode
    // toolbar={toolbar}
    // rightAction={{ icon: 'compass' }}

      // messagesRef={msgRef}
    // onToolbarClick={handleToolbarClick}
    recorder={{ canRecord: true,volume:0.5 ,onStart:onStart() ,onCancel:onCancel()}}
    // RecorderHandle={{canRecord:true}}
    wideBreakpoint="1000px"
   
      navbar={{ title: '小酷', }}
      messages={messages}
      renderMessageContent={renderMessageContent}
      quickReplies={defaultQuickReplies}
      onQuickReplyClick={handleQuickReplyClick}
      onSend={handleSend}
     
    />
  );
}



// export default App;
